<template>
    <div>
        <div class="max-w-xl mx-auto px-4 md:px-6 px-8">
            <TinyNavigation :prev="{name: 'match.create.2'}" :quit="{name: 'matches' }" class="pt-8 -px-8" />

            <h1 class="text-white font-semibold text-2xl pt-4 pb-4 text-center whitespace-pre-line" v-text="t('page_title')" />
                
            <form @submit.prevent="submitForm" class="flex-auto flex flex-col">
                <div class="flex-auto">
                    <SelectInput v-model:value="form.position"  v-model:errors="errors.position" :options="position_options" />
                </div>
                    
                <div class="flex-initial pb-10 pt-10">
                    <span class="block w-full rounded-md shadow-sm">
                        <button type="submit" class="button w-full">
                            {{ t('next_step') }}
                        </button>
                    </span>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import SelectInput from "@/components/forms/SelectInput";
import TinyNavigation from "@/components/TinyNavigation";
import { updateMatch, getMatchProperty } from "@/helpers/api/MatchHelper";
import { indexPositions } from "@/helpers/api/SportHelper";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "MatchCreate3",
    props: ['id'],
    components: { SelectInput, TinyNavigation },
    setup() {
        return useI18n({
            viewPrefix: "MatchCreate3"
        });
    },
    methods:{
        submitForm(){
            startSpinner();

            updateMatch(this.form, this.id)
                .then(() => {
                    this.$router.push({name: 'match.create.4', params: {id: this.id}})
                })
                .catch(err => {
                    this.errors = err.errors;
                })
                .finally(() => {
                    stopSpinner();
                });
        },
    },
    data(){
        return {
            form: {
                position: 'GK',
            },
            errors:{
                position: [],
            },
            position_options: [],
        };
    },
    created(){
        indexPositions().
            then(sport_positions => {
                this.position_options = sport_positions.map(pos => {return {key: pos.abbreviation, text: pos.name}});
            });
        getMatchProperty('position', this.id)
            .then(val => {
                if (val !== undefined || val !== null){
                    this.form.position = val;
                }
            });
    },
}
</script>

<style scoped>

</style>